import { Box, List, ListItem, Menu, Tooltip } from '@veracity/vui'
import type { Entity, Report, TenantReport } from 'libs/global'
import { useEffect } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useNavigate } from 'react-router-dom'
import shallow from 'zustand/shallow'

import { useGlobalStore } from 'stores/global'

import { OverflowText, SelectedListItem, TooltipDiv } from './styles'

type LeftPanelProps = {
    entityId: number
    reportId: number
    tenantReport: TenantReport
}

type MatchedEntityReport = {
    entity: Entity
    report: Report
}

const LeftPanel = (props: LeftPanelProps) => {
    const navigate = useNavigate()
    const entities = props.tenantReport.tenant.entities
    const { selectedEntity, setSelectedEntity, selectedReport, setSelectedReport } = useGlobalStore(
        state => ({
            selectedEntity: state.selectedEntity,
            setSelectedEntity: state.setSelectedEntity,
            selectedReport: state.selectedReport,
            setSelectedReport: state.setSelectedReport
        }),
        shallow
    )

    const onEntitySelected = (entity: Entity) => {
        let report
        if (entity.reports.length > 0) report = entity.reports[0]
        setSelectedEntity(entity)
        report && setSelectedReport(report)

        const targetUrl = `/${props.tenantReport.tenant.domainName.toLowerCase()}/entity/${entity.entityTreeId}${report ? `/report/${report.id}` :''}`
        navigate(targetUrl, { replace: true })
    }

    const onReportSelected = (report: Report) => {
        setSelectedReport(report)
        navigate(`/${props.tenantReport.tenant.domainName.toLowerCase()}/entity/${selectedEntity.entityTreeId}/report/${report.id}`)
    }

    const findMatchedResult = (): MatchedEntityReport => {
        let res: MatchedEntityReport = null
        props.tenantReport.tenant.entities.forEach(e => {
            e.reports.forEach(r => {
                if (r.id === props.reportId && e.entityTreeId === props.entityId) {
                    res = {
                        entity: e,
                        report: r
                    }
                }
            })
        })
        return res
    }

    useEffect(() => {
        if (props.reportId > 0) {
            const result = findMatchedResult()
            if (result && result.entity && result.report) {
                setSelectedEntity(result.entity)
                setSelectedReport(result.report)
            }
        }
    }, [props.reportId])

    useEffect(() => {
        if (!selectedReport && props.reportId === 0) {
            navigate(`/${props.tenantReport.tenant.domainName.toLowerCase()}/entity/${entities[0].entityTreeId}/report/${entities[0].reports[0].id}`)
        }
    }, [selectedReport])

    return (
        <Box borderRightColor="#e1e6e9" borderRightWidth="1px" className="left-panel" column>
            <Menu>
                <Menu.Button className="selected-entity" color="blue.100" iconRight="falAngleDown" title=" ">
                    <OverflowText>{selectedEntity && selectedEntity.name}</OverflowText>
                </Menu.Button>
                {entities && (
                    <Menu.List className="entity-list" h={480} heading="Switch Entity..." maxW={360}>
                        {entities.map(e => (
                            <Menu.Item
                                className="entity-item"
                                fontSize="16px"
                                isSelected={selectedEntity && selectedEntity.id === e.id}
                                key={e.id}
                                onClick={() => onEntitySelected(e)}
                                py="16px"
                            >
                                <Tooltip text={e.name}>
                                    <OverflowText>{e.name}</OverflowText>
                                </Tooltip>
                            </Menu.Item>
                        ))}
                    </Menu.List>
                )}
            </Menu>
            {selectedEntity && selectedEntity.reports.length > 0 && (
                <List overflow="auto">
                    {selectedEntity.reports.map(r => {
                        if (selectedReport && selectedReport.id === r.id)
                            return (
                                <SelectedListItem isSelected key={r.id} onClick={() => onReportSelected(r)}>
                                    <Box ml="10px">{r.name}</Box>
                                </SelectedListItem>
                            )
                        return (
                            <ListItem alignItems="stretch" key={r.id} onClick={() => onReportSelected(r)}>
                                <OverlayTrigger overlay={<TooltipDiv id={`tooltip-${r.id}`}>{r.name}</TooltipDiv>} placement="bottom">
                                    <Box centerV>
                                        <OverflowText>{r.name}</OverflowText>
                                    </Box>
                                </OverlayTrigger>
                            </ListItem>
                        )
                    })}
                </List>
            )}
        </Box>
    )
}

export default LeftPanel
