import { Button, Environment, LoggedOutHeader, useHeaderLinks, useLoadLinks } from '@veracity/vui'
import shallow from 'zustand/shallow'

import { useGlobalStore } from 'stores/global'

const getHeaderEnv = (): Environment => {
    let headerEnv: Environment = null
    switch ((window.__VAP_GLOBALS__.config.env as string).toLowerCase()) {
        case 'development':
        case 'devtest':
        case 'test':
            headerEnv = 'Test'
            break
        case 'stag':
            headerEnv = 'Stag'
            break
        case 'prod':
            headerEnv = 'Prod'
            break
        default:
            headerEnv = 'Test'
    }
    return headerEnv
}

const getLogoURL = (): string => {
    const rootUrl = new URL(window.__VAP_GLOBALS__.config.rootUrl)
    let logoUrl = 'https://onedesign.azureedge.net/onedesigncdn/2.6.0/images/DNV_logo_RGB.svg'
    if (rootUrl.hostname.endsWith('veracity.com')) logoUrl = 'https://cdn.veracity.com/common/icons/logos/veracity-logo.svg'
    return logoUrl
}

export default function Header() {
    const headerEnv = getHeaderEnv()
    useLoadLinks(headerEnv, true)
    const { mainLinks } = useHeaderLinks(headerEnv)
    const { tenantName } = useGlobalStore(
        state => ({
            tenantName: state.tenantName
        }),
        shallow
    )

    const gotoMyservice = () => {
        if (headerEnv === 'Test') window.location.href = 'https://servicestest.veracity.com'
        if (headerEnv === 'Stag') window.location.href = 'https://servicesstag.veracity.com'
        if (headerEnv === 'Prod') window.location.href = 'https://services.veracity.com'
    }

    const logoLink = {
        url: `/${tenantName ?? ''}`,
        src: getLogoURL()
    }

    return (
        <>
            <LoggedOutHeader logo={logoLink} mainLinks={mainLinks} signIn={<Button onClick={() => gotoMyservice()}>Sign in</Button>}></LoggedOutHeader>
        </>
    )
}
