import { Box } from '@veracity/vui'
import { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

type pdfProps = { id: string; report: any }

const ReactPDF = (props: pdfProps) => {
    const [numPages, setNumPages] = useState(null)
    const [pdfData, setPdfData] = useState(null)

    const onDocumentLoadSuccess = ({ numPages }): void => {
        setNumPages(numPages)
    }

    useEffect(() => {
        if (props.report) {
            setPdfData(`data:application/pdf;base64,${props.report}`)
        }
    }, [props.report])

    return (
        <Box column id={props.id} overflowX="hidden" overflowY="auto">
            <Box className="printArea pdf-viewer" column>
                {pdfData && (
                    <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess} renderMode="canvas">
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                )}
            </Box>
        </Box>
    )
}

export default ReactPDF
